import React, { useState } from 'react'
import './Header.css'
import { RiInstagramFill } from "react-icons/ri"
import { FaTiktok } from "react-icons/fa"
import { FaFacebookF } from "react-icons/fa"
import { FaYoutube } from "react-icons/fa"
import { RxHamburgerMenu } from 'react-icons/rx'
import { RxCross1 } from 'react-icons/rx'
import { useNavigate } from 'react-router-dom'

function Header() {
  const logo = require('../assets/logo_zwart_transparant.png')
  const [activeMenu, setActiveMenu] = useState('home')
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const navigate = useNavigate()

  const handleSmoothScroll = (sectionId, menuItem) => {
    if (window.location.pathname !== '/') {
      navigate('/')
      setTimeout(() => {
        const element = document.getElementById(sectionId)
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' })
        }
      }, 100)
    } else {
      const element = document.getElementById(sectionId)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
    setActiveMenu(menuItem)
    setIsMenuOpen(false)
  }

  return (
    <div className="sub_section header">
      <img className='logo' src={logo} alt="Pole District 85 Logo" onClick={() => navigate('/')} />
      <div className='menu desktop'>
        <a href="#" className={`menu_item ${activeMenu === 'home' ? 'active' : ''}`}
          onClick={(e) => {
            e.preventDefault();
            navigate('/');
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setActiveMenu('home');
          }}>Home</a>
        <a href="#about-section" className={`menu_item ${activeMenu === 'over-ons' ? 'active' : ''}`}
          onClick={(e) => {
            e.preventDefault()
            handleSmoothScroll('about-section', 'over-ons')
          }}>Over Ons</a>
        <a href="#courses-section" className={`menu_item ${activeMenu === 'lessen' ? 'active' : ''}`}
          onClick={(e) => {
            e.preventDefault()
            handleSmoothScroll('courses-section', 'lessen')
          }}>Lessen</a>
        <a href="#" className={`menu_item ${activeMenu === 'uurrooster' ? 'active' : ''}`}
          onClick={() => {
            setActiveMenu('uurrooster')
            navigate('/classes')
          }}>Uurrooster</a>
        {/* <a href="#" className={`menu_item ${activeMenu === 'prijzen-faq' ? 'active' : ''}`} onClick={() => setActiveMenu('prijzen-faq')}>Prijzen & FAQ</a> */}
        <a href="#" 
          className={`menu_item ${activeMenu === 'contact' ? 'active' : ''}`} 
          onClick={() => {
            setActiveMenu('contact')
            navigate('/contact')
          }}>Contact</a>
      </div>

      <div className='hamburger-icon mobile-only' onClick={() => setIsMenuOpen(!isMenuOpen)}>
        {isMenuOpen ? <RxCross1 /> : <RxHamburgerMenu />}
      </div>

      <div className={`menu mobile ${isMenuOpen ? 'open' : ''}`}>
        <a href="#" className={`menu_item`}
          onClick={(e) => {
            e.preventDefault();
            navigate('/');
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setActiveMenu('home');
            setIsMenuOpen(false);
          }}>Home</a>        <a href="#about-section" className={`menu_item`}
            onClick={(e) => {
              e.preventDefault()
              handleSmoothScroll('about-section', 'over-ons')
            }}>Over Ons</a>
        <a href="#courses-section" className={`menu_item`}
          onClick={(e) => {
            e.preventDefault()
            handleSmoothScroll('courses-section', 'lessen')
          }}>Lessen</a>
        <a href="#" className={`menu_item`}
          onClick={() => {
            setActiveMenu('uurrooster')
            navigate('/classes')
          }}>Uurrooster</a>
        {/* <a href="#" className={`menu_item`} onClick={() => setActiveMenu('prijzen-faq')}>Prijzen & FAQ</a> */}
        <a href="#" className={`menu_item`} 
          onClick={() => {
            setActiveMenu('contact')
            navigate('/contact')
          }}>Contact</a>
      </div>
      <div className='socials'>
        <RiInstagramFill className='social_item' onClick={() => window.open('https://www.instagram.com/poledistrict85?igsh=cXkwcTR6emFkaXNi', '_blank')} />
        {/* <FaTiktok className='social_item' onClick={() => window.open('https://www.tiktok.com/@poledistrict85', '_blank')} />
        <FaFacebookF className='social_item' onClick={() => window.open('https://www.facebook.com/poledistrict85', '_blank')} />
        <FaYoutube className='social_item' onClick={() => window.open('https://www.youtube.com/@poledistrict85', '_blank')} /> */}
      </div>
    </div>
  )
}

export default Header