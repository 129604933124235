import React from 'react'
import './Home.css'
import Header from '../components/Header'

function Home() {

  return (
    <div className="page_container" id="home-page">
      <section className="page_section" id="hero-section">
        <Header />
        <div className="sub_section welcome_section align-left">
          <p>Welkom Bij</p>
        </div>
        <div className="sub_section title_section">
          <h1>Pole District <br />85</h1>
        </div>
        <div className="sub_section address_section grid-2-right">
          <div></div>
          <div>Engelse wandeling 2 K16V 8500 Kortrijk</div>
        </div>
        <div className="sub_section no_padding image image_section">
          <picture>
            <source 
              media="(max-width: 768px)" 
              srcSet={require('../assets/Foto_Cover_Mobile.jpeg')} 
            />
            <img 
              src={require('../assets/Foto_Cover_2.png')} 
              alt="Pole District 85 Logo" 
            />
          </picture>
        </div>
      </section>

      <section className="page_section" id="about-section">
        <div className="sub_section no_padding">
          <div className="photo_box">
            <img src={require('../assets/Foto_3.png')} alt="Pole District 85 Team" />
          </div>
          <div className="content_box">
            <h2>Over Ons</h2>
            <p>
              Onze missie is om een plek te creeren waar kwaliteit voorop staat en waar iedereen zich thuis voelt.
            </p>
            <p>
              We bieden laagdrempelige begeleiding voor beginners en de nodige uitdaging voor gevorderden.
            </p>
            <p>
              We moedigen elkaar aan om beter te worden, zonder oordeel.
            </p>
          </div>
        </div>
      </section>

      <section className="page_section" id="courses-section">
        <div className="sub_section no_padding">
          <div className="photo_box">
            <video
              autoPlay
              loop
              muted
              playsInline
              poster={require('../assets/Foto_4.png')}
              className="video-element"
            >
              <source src={require('../assets/onze_lessen_video.mp4')} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="content_box">
            <h2>Ontdek Onze Lessen</h2>

            <div className='grid-2-right'>
              <div>
                <h3>Pole Intro</h3>
                <p>
                  Perfect voor beginners die willen kennismaken met paaldansen.
                </p>
              </div>
              <div>
                <h3>Pole Technique</h3>
                <p>
                  Technische lessen voor ervaren dansers, verdeeld over 6 niveaus.
                </p>
              </div>
              <div>
                <h3>Pole Exotic/Flow</h3>
                <p>
                  Choreografie en dans rond de paal. Exotic vereist pleasers, Flow kan op kousen.
                </p>
              </div>
              <div>
                <h3>Flexibility</h3>
                <p>
                  Lenigheidstraining voor iedereen, ook niet-paaldansers welkom!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="page_section" id="info-section">
        <div className='content_box'>
          <div className='title_box'>
            <h2>Eerste keer paaldans?</h2>
            <h3>Geen probleem! Hier is jouw gids voor een geweldige eerste keer</h3>
          </div>

          <div className='grid-2-right'>
            <div>
              <h3>Welke les moet ik boeken?</h3>
              <p>
                Pole Intro: Deze les is speciaal gemaakt voor complete beginners en zal je helpen om de basis onder de knie te krijgen.
              </p>
            </div>
            <div>
              <h3>Wat moet ik aandoen?</h3>
              <p>
                Comfortabele sportkledij voor de opwarming, als we de oefeningen aan de paal gaan doen heb je een korte aansluitende short nodig, want grip is belangrijk.
              </p>
            </div>
            <div>
              <h3>Kom op tijd</h3>
              <p>
                Onze lessen beginnen stipt op tijd. Kom minimaal 5 minuten op voorhand, zodat je tijd hebt om je om te kleden en je klaar te maken voor een geweldige les.
              </p>
            </div>
            <div>
              <h3>De opwarming is key!</h3>
              <p>
                De opwarming is niet alleen belangrijk voor jouw veiligheid, laar het bereidt jouw lichaam ook voor op de oefeningen die we zullen uitvoeren.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="page_section" id="instructors-section">
        <div className='sub_section grid-2-right no_padding'>
          <div><h2>Instructeurs</h2></div>
          <div className='content_box'>
            <p>
              Onze instructeurs hebben jarenlange ervaring en zijn gepassioneerd over het helpen van onze leerlingen om hun doelen te bereiken en volle potentieel te ontwikkelen. Ontdek hun stijl via hun instagram pagina.
            </p>
          </div>
        </div>
        <div className='content_box'>
          <div className='instructors-grid'>
            <div className='instructor-card'>
              <img src={require('../assets/images/gabriela.jpeg')} alt="Instructor Gabriela Pole District 85" />
              <h3>Gabriela</h3>
              <a href="https://www.instagram.com/aixagabriela_" target="_blank" rel="noopener noreferrer">
                @aixagabriela_
              </a>
            </div>
            <div className='instructor-card'>
              <img src={require('../assets/images/marlies.jpeg')} alt="Instructor Marlies Pole District 85" />
              <h3>Marlies</h3>
              <a href="https://www.instagram.com/marliezzzzz" target="_blank" rel="noopener noreferrer">
                @marliezzzzz
              </a>
            </div>
            <div className='instructor-card'>
              <img src={require('../assets/images/malaika.jpeg')} alt="Instructor Malaika Pole District 85" />
              <h3>Malaika</h3>
              <a href="https://www.instagram.com/malaikayaro" target="_blank" rel="noopener noreferrer">
                @malaikayaro
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>

  )
}

export default Home