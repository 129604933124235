import React from 'react'
import './ClassesPage.css'
import Header from '../components/Header'

function ClassesPage() {
  return (
    <div className="page_container" id="classes-page">
      <section className="page_section" id="hero-section">
        <Header />
        <div className="sub_section title_section">
          <h1>Uurrooster</h1>
        </div>
        <div className="sub_section schedule_section">
          <h2 className="schedule_title">Wekelijkse Uurrooster</h2>
          <p className="schedule_period">van 6/01 tot en met 30/3</p>
          
          <div className="schedule_grid">
            <div className="schedule_day">
              <h3>maandag</h3>
              <div className="class_slot">
                <p className="time">18:30-20:00</p>
                <p className="class_name">Pole Intro</p>
              </div>
              <div className="class_slot">
                <p className="time">20:15-21:45</p>
                <p className="class_name">Flexibility Training</p>
              </div>
            </div>

            <div className="schedule_day">
              <h3>dinsdag</h3>
              <div className="class_slot">
                <p className="time">18:30-20:00</p>
                <p className="class_name">Pole Exotic Intro</p>
              </div>
              <div className="class_slot">
                <p className="time">20:15-21:45</p>
                <p className="class_name">Pole Intro</p>
              </div>
            </div>

            <div className="schedule_day">
              <h3>woensdag</h3>
              <div className="class_slot">
                <p className="time">18:30-20:00</p>
                <p className="class_name">Pole Flow Intro</p>
              </div>
              <div className="class_slot">
                <p className="time">20:15-21:45</p>
                <p className="class_name">Pole Technique 1</p>
              </div>
            </div>

            <div className="schedule_day">
              <h3>donderdag</h3>
              <div className="class_slot">
                <p className="time">18:30-20:00</p>
                <p className="class_name">Pole Intro</p>
              </div>
              <div className="class_slot">
                <p className="time">20:15-21:45</p>
                <p className="class_name">Bootcamp Training</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default ClassesPage