import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import Home from './pages/Home'
import ClassesPage from './pages/ClassesPage'
import BookPage from './pages/BookPage'
import ContactPage from './pages/ContactPage'
function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/classes" element={<ClassesPage />} />
          <Route path="/book" element={<BookPage />} />
          <Route path="/contact" element={<ContactPage />} />
        </Routes>
        <BookButton />
      </div>
    </Router>
  )
}

// New component for the booking button
function BookButton() {
  const navigate = useNavigate()
  
  return (
    <button 
      onClick={() => navigate('/book')}
      className="book-button"
    >
      Boek je les
    </button>
  );
}

export default App
