import React from 'react'
import './ContactPage.css'
import Header from '../components/Header'

function ContactPage() {

  return (
    <div className="page_container" id="contact-page">
      <section className="page_section" id="hero-section">
        <Header />
        <div className="sub_section title_section">
          <h1>Contacteer ons</h1>
        </div>
        <div className="sub_section no_padding">
          <div className="contact_info_container">
            <div className="contact_column">
              <h2>Contact Info</h2>
              <p className='contact_item' onClick={() => window.open('tel:+32485473557')}>Telefoon: (+32) 485 47 35 57</p>
              <p className='contact_item' onClick={() => window.open('mailto:info@poledistrict85.be')}>Email: info@poledistrict85.be</p>
            </div>
            
            <div className="contact_column">
              <h2>Studio Adres</h2>
              <p>Engelse wandeling 2 K16V</p>
              <p>8500 Kortrijk</p>
            </div>
            
            <div className="contact_column">
              <h2>Openingsuren</h2>
              <p>Maandag - Vrijdag 10:00 - 22:00</p>
              <p>Zaterdag 10:00 - 18:00</p>
            </div>
          </div>
        </div>
      </section>
    </div>

  )
}

export default ContactPage