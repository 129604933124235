import React from 'react'
import './BookPage.css'
import Header from '../components/Header'

function BookPage() {

  return (
    <div className="page_container" id="book-page">
      <section className="page_section" id="hero-section">
        <Header />
        <div className="sub_section title_section">
          <h1>Boek je les</h1>
        </div>
        <div className="sub_section no_padding iframe_section">
          <iframe src="https://poledistrict85.trainin.app/widget/schedule" style={{width: '100%', height: '100%', border: 'none'}}></iframe>
        </div>
      </section>
    </div>

  )
}

export default BookPage